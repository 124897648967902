import { createStore } from 'vuex';

import auth from './modules/auth';
import LoadingState from './modules/loading';
import UserProfileStore from './modules/user';
import notificationsState from '../components/NotificationBar/store';

export default createStore({
  modules: {
    auth,
    LoadingState,
    UserProfileStore,
    notificationsState
  },
});
