import axios from 'axios';
import type { Module } from 'vuex';
import {
  CURRENT_REALM_NAME,
  IS_AUTHORIZED,
  KEYCLOAK_REALM,
  TOKEN,
  USER_KEYCLOAK_ID,
  USERNAME,
} from './getter-types';
import actions from './actions';
import {
  SET_AUTHORIZED,
  SET_UNAUTHORIZED,
  UPDATE_KEYCLOAK_REALM,
  UPDATE_SESSION_EXPIRY_TIME,
  UPDATE_USERNAME,
} from './mutation-types';
import AuthService from '../../../services/AuthService';
// import type { IKeycloakRealm, IToken } from '@/common/interfaces/auth/IToken';

export interface IAuthState {
  username: string
  registrationType: undefined
  token: any | null
  sessionExpiryTime: number
  keycloakRealm: any
}

const authState: Module<IAuthState, {}> = {
  state: {
    keycloakRealm: {
      current: '',
      lastVisited: '',
      loginInProgress: false,
      switchRealm: false,
    },
    username: '',
    registrationType: undefined,
    token: null,
    sessionExpiryTime: AuthService.getNewSessionExpiryTime(),
  },
  actions,
  mutations: {
    [SET_AUTHORIZED] (state, value) {
      state.token = value;
      // Add global auth header for every REST request
      axios.defaults.headers.common.Authorization = `bearer ${state.token?.accessToken}`;
      // axios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true';
      // axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'PUT, GET, POST, OPTIONS, DELETE, PATCH';
      // axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'https://portal.dev.dih-cloud.com';
    },
    [SET_UNAUTHORIZED] (state) {
      state.token = null;
    },
    [UPDATE_USERNAME] (state, value) {
      state.username = value;
    },
    [UPDATE_SESSION_EXPIRY_TIME] (state) {
      state.sessionExpiryTime = AuthService.getNewSessionExpiryTime();
    },
    [UPDATE_KEYCLOAK_REALM] (state, keycloakRealm: any) {
      state.keycloakRealm = {
        current: keycloakRealm.current,
        lastVisited: keycloakRealm.lastVisited,
        loginInProgress: keycloakRealm.loginInProgress,
        switchRealm: keycloakRealm.switchRealm,
      };
    },
  },
  getters: {
    [KEYCLOAK_REALM]: state => state.keycloakRealm,
    [CURRENT_REALM_NAME]: state => state.keycloakRealm.current,
    [IS_AUTHORIZED]: state => state.token !== null,
    [TOKEN]: state => state.token,
    [USERNAME]: state => state.username,
    [USER_KEYCLOAK_ID]: state => state.token!.keycloakId,
  },
};

export default authState;
