import { createI18n } from 'vue-i18n';
import en from './en.json';
import de from './de.json';
import es from './es.json';
import fr from './fr.json';

const i18n = createI18n({
  legacy: false,
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  availableLocales: ['en', 'de', 'es', 'fr'],
  messages: {
    en,
    de,
    es,
    fr
  }, // set locale messages
});

export default i18n;
