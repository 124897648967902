<template>
  <div class="app">
    <div
      class="dashboard-layout"
      :class="{ 'collapse': isSideBarCollapse }"
    >
      <AppNavigation class="is-nav-fixed" />
      <AppSideNavComponent
        :isCollapse="isSideBarCollapse"
        @onClickCollapse="onClickCollapse"
      />

      <div
        class="view"
        :class="{ 'collapse': isSideBarCollapse }"
      >
        <router-view />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, defineAsyncComponent } from 'vue';
import i18n from '../i18n/index';
import store from '../store';
import { LOGOUT } from '../store/modules/auth/action-types';

export default defineComponent({
  name: 'App',
  components: {
    AppSideNavComponent: defineAsyncComponent(() => import('@/components/AppSideNav/AppSideNavComponent.vue')),
    AppNavigation: defineAsyncComponent(() => import('@/components/AppNavigation/AppNavigation.vue')),
    TContent: defineAsyncComponent(() => import('@/components/TContent/TContent.vue')),
  },
  data() {
    return {
      languages: ['en', 'de'],
      isSideBarCollapse: false,
    };
  },
  methods: {
    onClickLanguage(language: any) {
      i18n.global.locale.value = language;
    },
    onClickCollapse (payload: boolean): void {
      console.log('colll', payload);
      
      this.isSideBarCollapse = payload;
    },
    logout() {
      store.dispatch(LOGOUT);
    }
  },
});
</script>

<style lang="scss" scoped>
@import "@/styles/utilities/variables";
@import "@/styles/utilities/mixins";

.dashboard-layout {
  padding-top: $nav-bar-height;
  padding-left: $t-sidebar-width;
  &.collapse {
    padding-left: $t-sidebar-width-collapse;
  }

  .t-header-language-switcher {
    display: flex;
    flex-direction: row;
    gap: var(--telekom-spacing-unit-x2);
  }

  .view {
    flex: 1;
    padding: 20px 30px 48px;
    min-height: 82vh;
    background: var(--telekom-color-background-canvas);
  }

  &:deep() {
    .app-navigation.is-nav-fixed {
      left: calc($t-sidebar-width - 1px);
      transition: left 0.2s ease-in-out;
    }
  }

  &.collapse {
    .app-navigation.is-nav-fixed {
      left: calc($t-sidebar-width-collapse - 1px);
    }
  }
}
</style>
