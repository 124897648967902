import { createRouter, createWebHistory } from 'vue-router'
import DashboardLayout from '../layouts/DashboardLayout.vue'
import store from '../store';
import { CHECK_TOKEN } from '../store/modules/auth/action-types';
import { SET_PAGE_BREADCRUMBS } from '../store/modules/user/mutation-types';
import { useRouteStore } from '../store/modules/routes';

const routeStore = useRouteStore();

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'dashboard',
      redirect: '/dashboard',
      component: DashboardLayout,
      children: [
        {
          path: 'dashboard',
          name: 'maindashboard',
          component: () => import('../components/Dashboard/Dashboard.vue'),
          meta: {
            navigation: [{
              title: 'Dashboard',
              translationKey: 'sideMenu.dashboard',
              routeName: 'dashboard',
            }, {
              title: '',
              translationKey: '',
              routeName: '',
            },{
              title: '',
              translationKey: '',
              routeName: 'dashboard',
            },

          ]
          }
        },
        {
          path: 'network-insights',
          name: 'networkinsights',
          component: () => import('../components/NetworkInsights/NetworkInsights.vue'),
          meta: {
            navigation: [{
              title: 'Dashboard',
              translationKey: 'sideMenu.dashboard',
              routeName: 'dashboard',
            }, {
              title: 'Network Insights',
              translationKey: 'sideMenu.networkInsights',
              routeName: 'networkinsights',
            }]
          }
        },
        {
          path: 'connector-settings',
          name: 'connectorsettings',
          component: () => import('../components/ConnectorSettings/ConnectorSettings.vue'),
          meta: {
            navigation: [{
              title: 'Connector Settings',
              translationKey: 'sideMenu.connectorSettings',
              routeName: 'connectorsettings',
            }]
          }
        },
        {
          path: 'transfer-history',
          name: 'transferhistory',
          component: () => import('../components/TransferHistory/TransferHistory.vue'),
          meta: {
            navigation: [{
              title: 'Transfer History',
              translationKey: 'sideMenu.transferHistory',
              routeName: 'transferhistory',
            }]
          }
        },
      ]
    }
  ]
});

router.beforeEach(async (to: any, from: any, next: any) => {
  store.commit(`UserProfileStore/${SET_PAGE_BREADCRUMBS}`, to.meta.navigation !== undefined && to.meta.navigation.length > 0 ? to.meta.navigation : []);
  await store.dispatch(CHECK_TOKEN, true);
  if (from.name === 'transferhistory') {
    routeStore.previousRoute.title = 'Transfer History';
    routeStore.previousRoute.routeName = 'transferhistory';
    routeStore.previousRoute.translationKey = 'sideMenu.transferHistory';
  } else if (from.name === 'networkinsights') {
    routeStore.previousRoute.title = 'Network Insights';
    routeStore.previousRoute.routeName = '';
    routeStore.previousRoute.translationKey = 'sideMenu.networkInsights';
  }
  next();
});

export default router
