<template>
  <LoadingBar />
  <NotificationBar />
  <RouterView />
  <SvgSprite />
</template>

<script setup lang="ts">
import { RouterView } from 'vue-router'
import LoadingBar from './components/LoadingBar/LoadingBar.vue';
import SvgSprite from './components/AppSvgIconsSprite/AppSvgIconsSprite.vue';
import NotificationBar from './components/NotificationBar/NotificationBar.vue';
import i18n from '@/i18n';
import Translation from './i18n/translation';

function setDefaultLocale () {
  i18n.global.locale.value = Translation.guessDefaultLocale();
}

setDefaultLocale()
</script>

<style lang="scss">
@import "./styles/main";

* {
  box-sizing: border-box;  
}

#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background: var(--telekom-color-background-canvas-subtle);
}

body {
    font-family: var(--font-family);
    color:var(--telekom-color-text-and-icon-standard);
    margin: 0;
}

.container {
  flex-grow: 1;
    margin: 0 auto;
    position: relative;
}

.view {
  // Sticky footer
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 260px;
;

  &.collapse {
    padding-left: 72px;
  }
}
:root {
  color-scheme: light only;
}
</style>
