import Keycloak, {
    KeycloakLoginOptions,
    KeycloakPromise} from 'keycloak-js';

const KEYCLOAK_CLIENT_ID = 'lila-example-app';
const MINIMAL_TOKEN_VALIDITY = 10; // min token validity in seconds
const TOKEN_EXPIRY_SECONDS = 300;
const SESSION_EXPIRY_SECONDS = 2700;
const redirectUri = `${location.origin}`;
const REGISTRATION_TYPE_KEYCLOAK_PARAM = 'registration_type';
const KEYCLOAK_LOCAL_STORAGE_KEY = 'keycloakRealm';

interface CallbackOneParam<T1 = void, T2 = void> {
    (param1: T1): T2;
}

export default class AuthService {
    static keycloakAuth = AuthService.getKeycloakInstance();

    static callLogin(onAuthenticatedCallback: CallbackOneParam): void {
        AuthService.keycloakInit().then(function (authenticated) {
            authenticated ? onAuthenticatedCallback() : alert("non authenticated");
          })
          .catch((e) => {
            console.dir(e);
            console.log(`keycloak init exception: ${e}`);
        });
    }

    /**
     * get keycloak instance
     * @param {string} realm name
     * @returns {Keycloak.KeycloakInstance} object with realm settings
     */
    static getKeycloakInstance(realmName?: string): Keycloak {
        let url = '';
        console.log(import.meta.env.VITE_APP_ENVIRONMENT);
        if (import.meta.env.VITE_APP_ENVIRONMENT === 'production') {
            url = 'https://id.lila.dih.telekom.com/';
        } else if (import.meta.env.VITE_APP_ENVIRONMENT === 'testing') {
            url = 'https://id-kc.lila-aks.dih-cloud.com/';
        } else {
            url = 'https://id.lila-dev.dih-cloud.com/';
        }
        return new Keycloak({
            url: url,
            realm: 'livinglab' || realmName as string,
            clientId: KEYCLOAK_CLIENT_ID as string
        });
    }

    /**
     * get keycloak ream from local storage
     * @returns {IKeycloakRealm} object with realm settings
     */
    static getKeycloakRealm(): any {
        let keycloakRealm: any = {};
        try {
            keycloakRealm = JSON.parse(localStorage.getItem(KEYCLOAK_LOCAL_STORAGE_KEY)!);
        } catch (_e) {
            return keycloakRealm;
        }
        return keycloakRealm instanceof Object ? keycloakRealm : {};
    }

    /**
     * set keycloak ream to local storage
     * @param {IKeycloakRealm} url image url part
     */
    static setKeycloakRealm(keycloakRealm: any) {
        localStorage.setItem(KEYCLOAK_LOCAL_STORAGE_KEY, JSON.stringify(keycloakRealm));
    }

    /**
     * Keycloak Init
     * will authenticate the client if the user is logged-in to Keycloak or display the login page if not
     */
    static keycloakInit(): KeycloakPromise<boolean, object> {
        return AuthService.keycloakAuth.init({ onLoad: "login-required" });
    }

    /**
     * Keycloak login
     * Redirects to login page
     */
    static keycloakLogin(options: KeycloakLoginOptions = {}): KeycloakPromise<void, void> {
        return AuthService.keycloakAuth.login(options);
    }

    /**
     * Gets user keycloak account
     */
    static getKeycloakProfile() {
        // need to extend KeycloakProfile model since it doesn't include `attributes` field
        return AuthService.keycloakAuth.loadUserProfile() as KeycloakPromise<any, void>;
    }

    /**
     * Gets realm access roles of user
     */
    static getRealmUserRoles(): string[] {
        return AuthService.keycloakAuth.realmAccess ? AuthService.keycloakAuth.realmAccess.roles : [];
    }

    /**
     * Gets resource access roles of user
     */
    static getResourceUserRoles(): any[] {
        const { resourceAccess } = AuthService.keycloakAuth;
        if (!resourceAccess) {
            return [];
        }
        const roleGroupNames = Object.keys(resourceAccess);
        return roleGroupNames.reduce<any[]>((rolesArray, groupName) => (
            [...rolesArray, ...resourceAccess[groupName].roles as any[]]
        ), []);
    }

    /**
     * Keycloak Logout
     */
    static keycloakLogout(): void {
        // Need to improve as per Kc 18: redirectURl not supported so use URL
        AuthService.keycloakAuth.logout();
        // window.location.href = `${KEYCLOAK_URL}realms/${DEFAULT_KEYCLOAK_REALM}/protocol/openid-connect/logout?post_logout_redirect_uri=${redirectUri}&id_token_hint=${idToken}`;
    }

    /**
     * Keycloak redirect to registration page
     */
    static keycloakRegister(type: any): void {
        const redirectUrl = new URL(redirectUri);

        redirectUrl.searchParams.append('welcome', 'true');

        const options: KeycloakLoginOptions = { redirectUri: redirectUrl.toString() };
        const url = new URL(AuthService.keycloakAuth.createRegisterUrl(options));

        url.searchParams.append(REGISTRATION_TYPE_KEYCLOAK_PARAM, type);
        window.location.assign(url.toString());
    }

    /**
     * Keycloak Refresh
     */
    static keycloakRefreshToken() {
        return AuthService.keycloakAuth.updateToken(MINIMAL_TOKEN_VALIDITY);
    }

    /**
     * Set onTokenExpired callback
     */
    static setKeycloakOnTokenExpired(onTokenExpired: () => void) {
        AuthService.keycloakAuth.onTokenExpired = onTokenExpired;
    }

    /**
     * Get new session expiry time
     */
    static getNewSessionExpiryTime() {
        return Date.now() + (SESSION_EXPIRY_SECONDS * 1000);
    }

    /**
     * Provide token info
     */
    static getToken(): any {
        return {
            expiresIn: TOKEN_EXPIRY_SECONDS,
            accessToken: this.keycloakAuth.token,
            refreshToken: this.keycloakAuth.refreshToken,
            idToken: this.keycloakAuth.idToken,
            keycloakId: this.keycloakAuth.subject
        };
    }

    /**
     * Verifies user's password
     */
    //   static checkPassword(username: string, password: string): Promise<boolean> {
    //     return axios.post<string>(Util.getRestApiUrl('identity/common/token'), {
    //       username,
    //       password
    //     }).then(res => res.status === 200);
    //   }
}

// Listening for changes of realm in other browser's tabs
window.addEventListener('storage', event => {
    if (event.key !== KEYCLOAK_LOCAL_STORAGE_KEY) {
        return;
    }

    const keycloakRealm = JSON.parse(event.newValue!);
    if (!keycloakRealm || keycloakRealm.loginInProgress) {
        return;
    }
    if (!keycloakRealm.current) {
        AuthService.keycloakLogout();
    }
}, false);
