import type { MutationTree } from 'vuex';
import {
  SET_USER_INFO,
  SET_IS_LOADING,
  SET_PAGE_BREADCRUMBS,
  SET_OFFER,
} from './mutation-types';
import type {
  IntUserProfileState,
  ICurrentRoute,
} from './index';

const mutations: MutationTree<IntUserProfileState> = {
  [SET_USER_INFO] (state, payload) {
    state.userInfo = payload;
  },
  [SET_IS_LOADING] (state, payload: boolean) {
    state.isLoading = payload;
  },
  [SET_OFFER] (state, payload: any) {
    state.userOffer = payload;
  },
  [SET_PAGE_BREADCRUMBS] (state, payload: ICurrentRoute[]) {
    state.breadcrumbs = [];
    setTimeout(() => {
      state.breadcrumbs = payload;
    }, 100);
  }
};
export default mutations;
