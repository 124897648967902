import type { GetterTree } from 'vuex';
import {
  GET_IS_LOADING,
  GET_USER_INFO,
  GET_PAGE_BREADCRUMBS,
  GET_ACTIVE_INVITATIONS,
  GET_USER_LANGUAGE,
GET_OFFER,
} from './getter-types';
import type {
  IntUserProfileState,
} from './index';

const getters: GetterTree<IntUserProfileState, {}> = {
  [GET_USER_INFO]: state => state.userInfo,
  [GET_IS_LOADING]: state => state.isLoading,
  [GET_PAGE_BREADCRUMBS]: state => state.breadcrumbs,
  [GET_ACTIVE_INVITATIONS]: state => state.activeInvitations,
  [GET_OFFER]: state => state.userOffer,
  [GET_USER_LANGUAGE]: state => state.userInfo !== null ? state.userInfo.language : null,
};

export default getters;
