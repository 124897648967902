import type { ActionTree } from 'vuex';
import type { IAuthState } from './index';
import {
  LOGIN,
  LOGOUT,
  CHECK_TOKEN,
  REFRESH_TOKEN,
  INIT_TOKEN,
  HANDLE_TOKEN_EXPIRY,
} from './action-types';
import {
  SET_AUTHORIZED,
  SET_UNAUTHORIZED,
} from './mutation-types';
import AuthService from '../../../services/AuthService';

let logoutTimeout: number;

const actions: ActionTree<IAuthState, {}> = {
  [LOGIN] () {
    return AuthService.keycloakLogin();
  },
  [LOGOUT] ({ commit }) {
    localStorage.removeItem('view-icon');
    commit(SET_UNAUTHORIZED);
    AuthService.keycloakLogout();
  },
  async [CHECK_TOKEN] ({ state, dispatch }, isLoginRequired?: boolean) {
    if (state.token !== null) {
      // Resolve if there's a token already and it's not a refresh request
      return 1;
    } else if (AuthService.keycloakAuth.idToken) {
      // Refresh otherwise via keycloak
      await dispatch(REFRESH_TOKEN);
      return 1;
    } else {
      // Initialize token if it's not present yet
      await dispatch(INIT_TOKEN, isLoginRequired);
      return 1;
    }
  },
  [REFRESH_TOKEN] ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      AuthService.keycloakRefreshToken()
        .then(refreshed => {
          if (refreshed) {
            commit(SET_AUTHORIZED, AuthService.getToken());
          }
          resolve(1);
        })
        .catch(() => {
          // redirect to login when session is expired
          // console.log('Failed to refresh the token, or the session has expired', error);
          void dispatch(LOGIN);
          reject();
        });
    });
  },
  async [INIT_TOKEN] ({ commit, dispatch }, isLoginRequired?: boolean) {
    try {
      AuthService.setKeycloakOnTokenExpired(() => dispatch(HANDLE_TOKEN_EXPIRY));
      await AuthService.keycloakInit();
      if (AuthService.keycloakAuth.authenticated) {
        // Set actual auth token
        commit(SET_AUTHORIZED, AuthService.getToken());
      } else {
        void dispatch(LOGIN);
        return 0;
      }
    } catch (error) {
      void dispatch(LOGIN);
      throw error;
    }
  },
  [HANDLE_TOKEN_EXPIRY] ({ state, dispatch }) {
    // clear logout timeout if it is present
    if (logoutTimeout) {
      window.clearTimeout(logoutTimeout);
    }
    const sessionExpiresIn = state.sessionExpiryTime - Date.now(); // ms
    if (sessionExpiresIn < 0) {
      // logout if session has expired
      void dispatch(LOGOUT);
    } else {
      // otherwise refresh token
      void dispatch(REFRESH_TOKEN, true);
      // set timeout to logout when session expiry time is less then token expiry time
      if (sessionExpiresIn < AuthService.getToken().expiresIn * 1000) {
        logoutTimeout = window.setTimeout(() => dispatch(HANDLE_TOKEN_EXPIRY), sessionExpiresIn);
      }
    }
  },
};

export default actions;
