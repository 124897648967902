<template>
  <TLoadingBar v-show="isLoading" class="loading-bar" :is-loading="isLoading || !isPageLoaded" />
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { LOADING_STATE } from '../../store/modules/loading/getter-types';
import { IS_AUTHORIZED } from '../../store/modules/auth/getter-types';
import TLoadingBar from '../TLoadingBar/TLoadingBar.vue';
import { useStore } from 'vuex';

export default defineComponent({
  components: {
    TLoadingBar,
  },
  setup() {
    const store = useStore();
    const isPageLoaded = ref<boolean>(false);

    window.addEventListener('load', () => {
      isPageLoaded.value = true;
    });

    const isLoading = computed((): boolean => {
      console.log(store.getters[LOADING_STATE]);
      return store.getters[LOADING_STATE];
    });

    const isAuthenticated = computed((): boolean => {
      return store.getters[IS_AUTHORIZED];
    });

    return {
      isLoading, isAuthenticated, isPageLoaded
    }
  }
});

</script>

<style lang="scss" scoped>
// @import '@/styles/utilities/variables';
$spinner-size-one:  24px;
$spinner-size-two: 36px;
$spinner-size-three: 48px;
$spinner-size-four: 72px;
$spinner-size-five: 120px;

.loading-bar {
  z-index: 101;
  background-color: #fff;

  &.is-generic {
    .loading-overlay-bar {
      background-color: grey;
    }
  }

  &.is-authenticated {
    z-index: 10;
  }
}
</style>
