<template>
  <div class="notification-bar">
    <TNotificationBar v-model="notifications">
      <TNotification
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
      >
        {{ notification.text }}
      </TNotification>
    </TNotificationBar>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { NOTIFICATION_LIST } from './store/getter-types';
import { UPDATE_NOTIFICATION_LIST } from './store/mutation-types';
import type { INotification } from '../notifications/INotification';
import TNotification from '../notifications/TNotification/TNotification.vue';
import TNotificationBar from '../notifications/TNotificationBar/TNotificationBar.vue';
import { useStore } from 'vuex';

const store = useStore();

const notifications = computed(
  mapTwoWay<INotification[]>(NOTIFICATION_LIST, UPDATE_NOTIFICATION_LIST),
);

function mapTwoWay<T>(getter: string, mutation: string) {
    return {
      get (): T {
        return store.getters[getter];
      },
      set (value: T) {
        store.commit(mutation, value);
      },
    };
  }
</script>
