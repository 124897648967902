// import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import { applyPolyfills, defineCustomElements } from '@telekom/scale-components/loader';
import '@telekom/scale-components/dist/scale-components/scale-components.css';
import store from './store';
import Vuelidate from 'vuelidate';
import i18n from './i18n';
import { OuterClick } from './directives/outerClick';

// import './interceptors';

applyPolyfills().then(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    defineCustomElements(window);
  });
const app = createApp(App)

app.directive('outer-click', OuterClick);

app.use(createPinia())
app.use(router)
app.use(i18n);
app.use(store);
app.use(Vuelidate);
app.mount('#app')
