import { reactive } from 'vue';

const routeStore = reactive({
  previousRoute: {
    title: '',
    routeName: '',
    translationKey: ''
  } as any | null,
});

export function useRouteStore() {
  return routeStore;
}
