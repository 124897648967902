import type { Module } from 'vuex';
import { type INotification, NotificationType } from '../../notifications/INotification';
import { NOTIFICATION_LIST } from './getter-types';
import {
  ADD_SUCCESS_NOTIFICATION,
  UPDATE_NOTIFICATION_LIST,
  ADD_ERROR_NOTIFICATION,
  ADD_HINT_NOTIFICATION,
  ADD_WARNING_NOTIFICATION,
} from './mutation-types';
import { v4 as uuidv4 } from 'uuid';

interface INotificationsState {
  notifications: INotification[]
}

type RawNotification = string | INotification;

function getNotification (notification: RawNotification): INotification {
  const id = generateUUID();
  return typeof notification === 'object'
    ? { id, ...notification }
    : { id, text: notification };
}

function generateUUID () {
  return uuidv4();
}

const notificationsState: Module<INotificationsState, {}> = {
  state: {
    notifications: [],
  },
  getters: {
    [NOTIFICATION_LIST]: state => state.notifications,
  },
  mutations: {
    [ADD_SUCCESS_NOTIFICATION] (state, notification: RawNotification) {
      state.notifications.unshift({ ...getNotification(notification), type: NotificationType.SUCCESS });
    },
    [ADD_ERROR_NOTIFICATION] (state, notification: RawNotification) {
      state.notifications.unshift({ ...getNotification(notification), type: NotificationType.ERROR });
    },
    [ADD_HINT_NOTIFICATION] (state, notification: RawNotification) {
      state.notifications.unshift({ ...getNotification(notification), type: NotificationType.HINT });
    },
    [ADD_WARNING_NOTIFICATION] (state, notification: RawNotification) {
      state.notifications.unshift({ ...getNotification(notification), type: NotificationType.WARNING });
    },
    [UPDATE_NOTIFICATION_LIST] (state, value: INotification[]) {
      state.notifications = value;
    },
  },
};

export default notificationsState;
